import React from 'react';
import './App.css';


function CountryTable({ countryData, onRowClick }) {
  return (
    
    <div id="tabela">
      <table>
        <thead>
          <tr>
            <th>Country</th>
            <th>Code</th>
            <th>ISO</th>
          </tr>
        </thead>
        <tbody>
          {countryData.map((country, index) => (
            <tr key={index} onClick={() => onRowClick(`+${country.code}`)} style={{cursor: 'pointer'}}>
              <td>{country.country}</td>
              <td> +{country.code}</td>
              <td>{country.iso}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CountryTable;
