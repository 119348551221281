import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CountryTable from './CountryTable';
import './App.css';
import moment from 'moment-timezone';

const countryData = [
  { "country": "Afghanistan", "code": "93", "iso": "AF", "timezone": "Asia/Kabul" },
  { "country": "Albania", "code": "355", "iso": "AL", "timezone": "Europe/Tirane" },
  { "country": "Algeria", "code": "213", "iso": "DZ", "timezone": "Africa/Algiers" },
  { "country": "American Samoa", "code": "1-684", "iso": "AS", "timezone": "Pacific/Pago_Pago" },
  { "country": "Andorra", "code": "376", "iso": "AD", "timezone": "Europe/Andorra" },
  { "country": "Angola", "code": "244", "iso": "AO", "timezone": "Africa/Luanda" },
  { "country": "Anguilla", "code": "1-264", "iso": "AI", "timezone": "America/Anguilla" },
  { "country": "Antarctica", "code": "672", "iso": "AQ", "timezone": "Antarctica/Casey" },
  { "country": "Antigua and Barbuda", "code": "1-268", "iso": "AG", "timezone": "America/Antigua" },
  { "country": "Argentina", "code": "54", "iso": "AR", "timezone": "America/Argentina/Buenos_Aires" },
  { "country": "Armenia", "code": "374", "iso": "AM", "timezone": "Asia/Yerevan" },
  { "country": "Aruba", "code": "297", "iso": "AW", "timezone": "America/Aruba" },
  { "country": "Australia", "code": "61", "iso": "AU", "timezone": "Australia/Sydney" },
  { "country": "Austria", "code": "43", "iso": "AT", "timezone": "Europe/Vienna" },
  { "country": "Azerbaijan", "code": "994", "iso": "AZ", "timezone": "Asia/Baku" },
  { "country": "Bahamas", "code": "1-242", "iso": "BS", "timezone": "America/Nassau" },
  { "country": "Bahrain", "code": "973", "iso": "BH", "timezone": "Asia/Bahrain" },
  { "country": "Bangladesh", "code": "880", "iso": "BD", "timezone": "Asia/Dhaka" },
  { "country": "Barbados", "code": "1-246", "iso": "BB", "timezone": "America/Barbados" },
  { "country": "Belarus", "code": "375", "iso": "BY", "timezone": "Europe/Minsk" },
  { "country": "Belgium", "code": "32", "iso": "BE", "timezone": "Europe/Brussels" },
  { "country": "Belize", "code": "501", "iso": "BZ", "timezone": "America/Belize" },
  { "country": "Benin", "code": "229", "iso": "BJ", "timezone": "Africa/Porto-Novo" },
  { "country": "Bermuda", "code": "1-441", "iso": "BM", "timezone": "Atlantic/Bermuda" },
  { "country": "Bhutan", "code": "975", "iso": "BT", "timezone": "Asia/Thimphu" },
  { "country": "Bolivia", "code": "591", "iso": "BO", "timezone": "America/La_Paz" },
  { "country": "Bosnia and Herzegovina", "code": "387", "iso": "BA", "timezone": "Europe/Sarajevo" },
  { "country": "Botswana", "code": "267", "iso": "BW", "timezone": "Africa/Gaborone" },
  { "country": "Brazil", "code": "55", "iso": "BR", "timezone": "America/Sao_Paulo" },
  { "country": "British Indian Ocean Territory", "code": "246", "iso": "IO", "timezone": "Indian/Chagos" },
  { "country": "British Virgin Islands", "code": "1-284", "iso": "VG", "timezone": "America/Tortola" },
  { "country": "Brunei", "code": "673", "iso": "BN", "timezone": "Asia/Brunei" },
  { "country": "Bulgaria", "code": "359", "iso": "BG", "timezone": "Europe/Sofia" },
  { "country": "Burkina Faso", "code": "226", "iso": "BF", "timezone": "Africa/Ouagadougou" },
  { "country": "Burundi", "code": "257", "iso": "BI", "timezone": "Africa/Bujumbura" },
  { "country": "Cambodia", "code": "855", "iso": "KH", "timezone": "Asia/Phnom_Penh" },
  { "country": "Cameroon", "code": "237", "iso": "CM", "timezone": "Africa/Douala" },
  { "country": "Canada", "code": "1", "iso": "CA", "timezone": "America/Toronto" }, // Note: Canada spans multiple time zones
  { "country": "Cape Verde", "code": "238", "iso": "CV", "timezone": "Atlantic/Cape_Verde" },
  { "country": "Cayman Islands", "code": "1-345", "iso": "KY", "timezone": "America/Cayman" },
  { "country": "Central African Republic", "code": "236", "iso": "CF", "timezone": "Africa/Bangui" },
  { "country": "Chad", "code": "235", "iso": "TD", "timezone": "Africa/Ndjamena" },
  { "country": "Chile", "code": "56", "iso": "CL", "timezone": "America/Santiago" },
  { "country": "China", "code": "86", "iso": "CN", "timezone": "Asia/Shanghai" },
  { "country": "Christmas Island", "code": "61", "iso": "CX", "timezone": "Indian/Christmas" },
  { "country": "Cocos Islands", "code": "61", "iso": "CC", "timezone": "Indian/Cocos" },
  { "country": "Colombia", "code": "57", "iso": "CO", "timezone": "America/Bogota" },
  { "country": "Comoros", "code": "269", "iso": "KM", "timezone": "Indian/Comoro" },
  { "country": "Cook Islands", "code": "682", "iso": "CK", "timezone": "Pacific/Rarotonga" },
  { "country": "Costa Rica", "code": "506", "iso": "CR", "timezone": "America/Costa_Rica" },
  { "country": "Croatia", "code": "385", "iso": "HR", "timezone": "Europe/Zagreb" },
  { "country": "Cuba", "code": "53", "iso": "CU", "timezone": "America/Havana" },
  { "country": "Curacao", "code": "599", "iso": "CW", "timezone": "America/Curacao" },
  { "country": "Cyprus", "code": "357", "iso": "CY", "timezone": "Asia/Nicosia" },
  { "country": "Czech Republic", "code": "420", "iso": "CZ", "timezone": "Europe/Prague" },
  { "country": "Democratic Republic of the Congo", "code": "243", "iso": "CD", "timezone": "Africa/Kinshasa" }, // Note: DRC spans multiple time zones
  { "country": "Denmark", "code": "45", "iso": "DK", "timezone": "Europe/Copenhagen" },
  { "country": "Djibouti", "code": "253", "iso": "DJ", "timezone": "Africa/Djibouti" },
  { "country": "Dominica", "code": "1-767", "iso": "DM", "timezone": "America/Dominica" },
  { "country": "Dominican Republic", "code": "1-809, 1-829, 1-849", "iso": "DO", "timezone": "America/Santo_Domingo" },
  { "country": "East Timor", "code": "670", "iso": "TL", "timezone": "Asia/Dili" },
  { "country": "Ecuador", "code": "593", "iso": "EC", "timezone": "America/Guayaquil" },
  { "country": "Egypt", "code": "20", "iso": "EG", "timezone": "Africa/Cairo" },
  { "country": "El Salvador", "code": "503", "iso": "SV", "timezone": "America/El_Salvador" },
  { "country": "Equatorial Guinea", "code": "240", "iso": "GQ", "timezone": "Africa/Malabo" },
  { "country": "Eritrea", "code": "291", "iso": "ER", "timezone": "Africa/Asmara" },
  { "country": "Estonia", "code": "372", "iso": "EE", "timezone": "Europe/Tallinn" },
  { "country": "Ethiopia", "code": "251", "iso": "ET", "timezone": "Africa/Addis_Ababa" },
  { "country": "Falkland Islands", "code": "500", "iso": "FK" },
  { "country": "Falkland Islands", "code": "500", "iso": "FK", "timezone": "Atlantic/Stanley" },
  { "country": "Faroe Islands", "code": "298", "iso": "FO", "timezone": "Atlantic/Faroe" },
  { "country": "Fiji", "code": "679", "iso": "FJ", "timezone": "Pacific/Fiji" },
  { "country": "Finland", "code": "358", "iso": "FI", "timezone": "Europe/Helsinki" },
  { "country": "France", "code": "33", "iso": "FR", "timezone": "Europe/Paris" },
  { "country": "French Polynesia", "code": "689", "iso": "PF", "timezone": "Pacific/Tahiti" },
  { "country": "Gabon", "code": "241", "iso": "GA", "timezone": "Africa/Libreville" },
  { "country": "Gambia", "code": "220", "iso": "GM", "timezone": "Africa/Banjul" },
  { "country": "Georgia", "code": "995", "iso": "GE", "timezone": "Asia/Tbilisi" },
  { "country": "Germany", "code": "49", "iso": "DE", "timezone": "Europe/Berlin" },
  { "country": "Ghana", "code": "233", "iso": "GH", "timezone": "Africa/Accra" },
  { "country": "Gibraltar", "code": "350", "iso": "GI", "timezone": "Europe/Gibraltar" },
  { "country": "Greece", "code": "30", "iso": "GR", "timezone": "Europe/Athens" },
  { "country": "Greenland", "code": "299", "iso": "GL", "timezone": "America/Nuuk" },
  { "country": "Grenada", "code": "1-473", "iso": "GD", "timezone": "America/Grenada" },
  { "country": "Guam", "code": "1-671", "iso": "GU", "timezone": "Pacific/Guam" },
  { "country": "Guatemala", "code": "502", "iso": "GT", "timezone": "America/Guatemala" },
  { "country": "Guernsey", "code": "44-1481", "iso": "GG", "timezone": "Europe/Guernsey" },
  { "country": "Guinea", "code": "224", "iso": "GN", "timezone": "Africa/Conakry" },
  { "country": "Guinea-Bissau", "code": "245", "iso": "GW", "timezone": "Africa/Bissau" },
  { "country": "Guyana", "code": "592", "iso": "GY", "timezone": "America/Guyana" },
  { "country": "Haiti", "code": "509", "iso": "HT", "timezone": "America/Port-au-Prince" },
  { "country": "Honduras", "code": "504", "iso": "HN", "timezone": "America/Tegucigalpa" },
  { "country": "Hong Kong", "code": "852", "iso": "HK", "timezone": "Asia/Hong_Kong" },
  { "country": "Hungary", "code": "36", "iso": "HU", "timezone": "Europe/Budapest" },
  { "country": "Iceland", "code": "354", "iso": "IS", "timezone": "Atlantic/Reykjavik" },
  { "country": "India", "code": "91", "iso": "IN", "timezone": "Asia/Kolkata" },
  { "country": "Indonesia", "code": "62", "iso": "ID", "timezone": "Asia/Jakarta" }, // Note: Indonesia spans multiple time zones
  { "country": "Iran", "code": "98", "iso": "IR", "timezone": "Asia/Tehran" },
  { "country": "Iraq", "code": "964", "iso": "IQ", "timezone": "Asia/Baghdad" },
  { "country": "Ireland", "code": "353", "iso": "IE", "timezone": "Europe/Dublin" },
  { "country": "Isle of Man", "code": "44-1624", "iso": "IM", "timezone": "Europe/Isle_of_Man" },
  { "country": "Israel", "code": "972", "iso": "IL", "timezone": "Asia/Jerusalem" },
  { "country": "Italy", "code": "39", "iso": "IT", "timezone": "Europe/Rome" },
  { "country": "Ivory Coast", "code": "225", "iso": "CI", "timezone": "Africa/Abidjan" },
  { "country": "Jamaica", "code": "1-876", "iso": "JM", "timezone": "America/Jamaica" },
  { "country": "Japan", "code": "81", "iso": "JP", "timezone": "Asia/Tokyo" },
  { "country": "Jersey", "code": "44-1534", "iso": "JE", "timezone": "Europe/Jersey" },
  { "country": "Jordan", "code": "962", "iso": "JO", "timezone": "Asia/Amman" },
  { "country": "Kazakhstan", "code": "7", "iso": "KZ", "timezone": "Asia/Almaty" }, // Note: Kazakhstan spans multiple time zones
  { "country": "Kenya", "code": "254", "iso": "KE", "timezone": "Africa/Nairobi" },
  { "country": "Kiribati", "code": "686", "iso": "KI", "timezone": "Pacific/Tarawa" },
  { "country": "Kosovo", "code": "383", "iso": "XK", "timezone": "Europe/Belgrade" }, // Note: Using Belgrade as no official timezone
  { "country": "Kuwait", "code": "965", "iso": "KW", "timezone": "Asia/Kuwait" },
  { "country": "Kyrgyzstan", "code": "996", "iso": "KG", "timezone": "Asia/Bishkek" },
  { "country": "Laos", "code": "856", "iso": "LA", "timezone": "Asia/Vientiane" },
  { "country": "Latvia", "code": "371", "iso": "LV", "timezone": "Europe/Riga" },
  { "country": "Lebanon", "code": "961", "iso": "LB", "timezone": "Asia/Beirut" },
  { "country": "Lesotho", "code": "266", "iso": "LS", "timezone": "Africa/Maseru" },
  { "country": "Liberia", "code": "231", "iso": "LR", "timezone": "Africa/Monrovia" },
  { "country": "Libya", "code": "218", "iso": "LY", "timezone": "Africa/Tripoli" },
  { "country": "Liechtenstein", "code": "423", "iso": "LI", "timezone": "Europe/Vaduz" },
  { "country": "Lithuania", "code": "370", "iso": "LT", "timezone": "Europe/Vilnius" },
  { "country": "Luxembourg", "code": "352", "iso": "LU", "timezone": "Europe/Luxembourg" },
  { "country": "Macao", "code": "853", "iso": "MO", "timezone": "Asia/Macau" },
  { "country": "Macedonia", "code": "389", "iso": "MK", "timezone": "Europe/Skopje" },
  { "country": "Madagascar", "code": "261", "iso": "MG", "timezone": "Indian/Antananarivo" },
  { "country": "Malawi", "code": "265", "iso": "MW", "timezone": "Africa/Blantyre" },
  { "country": "Malaysia", "code": "60", "iso": "MY", "timezone": "Asia/Kuala_Lumpur" },
  { "country": "Maldives", "code": "960", "iso": "MV", "timezone": "Indian/Maldives" },
  { "country": "Mali", "code": "223", "iso": "ML", "timezone": "Africa/Bamako" },
  { "country": "Malta", "code": "356", "iso": "MT", "timezone": "Europe/Malta" },
  { "country": "Marshall Islands", "code": "692", "iso": "MH", "timezone": "Pacific/Majuro" },
  { "country": "Mauritania", "code": "222", "iso": "MR", "timezone": "Africa/Nouakchott" },
  { "country": "Mauritius", "code": "230", "iso": "MU", "timezone": "Indian/Mauritius" },
  { "country": "Mayotte", "code": "262", "iso": "YT", "timezone": "Indian/Mayotte" },
  { "country": "Mexico", "code": "52", "iso": "MX", "timezone": "America/Mexico_City" },  // Note: Mexico spans multiple time zones
  { "country": "Micronesia", "code": "691", "iso": "FM", "timezone": "Pacific/Pohnpei" },
  { "country": "Moldova", "code": "373", "iso": "MD", "timezone": "Europe/Chisinau" },
  { "country": "Monaco", "code": "377", "iso": "MC", "timezone": "Europe/Monaco" },
  { "country": "Mongolia", "code": "976", "iso": "MN", "timezone": "Asia/Ulaanbaatar" }, // Note: Mongolia spans multiple time zones
  { "country": "Montenegro", "code": "382", "iso": "ME", "timezone": "Europe/Podgorica" },
  { "country": "Montserrat", "code": "1-664", "iso": "MS", "timezone": "America/Montserrat" },
  { "country": "Morocco", "code": "212", "iso": "MA", "timezone": "Africa/Casablanca" },
  { "country": "Mozambique", "code": "258", "iso": "MZ", "timezone": "Africa/Maputo" },
  { "country": "Myanmar", "code": "95", "iso": "MM", "timezone": "Asia/Yangon" },
  { "country": "Namibia", "code": "264", "iso": "NA", "timezone": "Africa/Windhoek" },
  { "country": "Nauru", "code": "674", "iso": "NR", "timezone": "Pacific/Nauru" },
  { "country": "Nepal", "code": "977", "iso": "NP", "timezone": "Asia/Kathmandu" },
  { "country": "Netherlands", "code": "31", "iso": "NL", "timezone": "Europe/Amsterdam" },
  { "country": "Netherlands Antilles", "code": "599", "iso": "AN", "timezone": "America/Curacao" }, // Former country, consider updating
  { "country": "New Caledonia", "code": "687", "iso": "NC", "timezone": "Pacific/Noumea" },
  { "country": "New Zealand", "code": "64", "iso": "NZ", "timezone": "Pacific/Auckland" }, // Note: Includes Chatham Islands as another timezone
  { "country": "Nicaragua", "code": "505", "iso": "NI", "timezone": "America/Managua" },
  { "country": "Niger", "code": "227", "iso": "NE", "timezone": "Africa/Niamey" },
  { "country": "Nigeria", "code": "234", "iso": "NG", "timezone": "Africa/Lagos" },
  { "country": "Niue", "code": "683", "iso": "NU", "timezone": "Pacific/Niue" },
  { "country": "North Korea", "code": "850", "iso": "KP", "timezone": "Asia/Pyongyang" },
  { "country": "Northern Mariana Islands", "code": "1-670", "iso": "MP", "timezone": "Pacific/Saipan" },
  { "country": "Norway", "code": "47", "iso": "NO", "timezone": "Europe/Oslo" },
  { "country": "Oman", "code": "968", "iso": "OM", "timezone": "Asia/Muscat" },
  { "country": "Pakistan", "code": "92", "iso": "PK", "timezone": "Asia/Karachi" },
  { "country": "Palau", "code": "680", "iso": "PW", "timezone": "Pacific/Palau" },
  { "country": "Palestine", "code": "970", "iso": "PS", "timezone": "Asia/Gaza" }, // Consider more specific handling for West Bank and Gaza
  { "country": "Panama", "code": "507", "iso": "PA", "timezone": "America/Panama" },
  { "country": "Papua New Guinea", "code": "675", "iso": "PG", "timezone": "Pacific/Port_Moresby" },
  { "country": "Paraguay", "code": "595", "iso": "PY", "timezone": "America/Asuncion" },
  { "country": "Peru", "code": "51", "iso": "PE", "timezone": "America/Lima" },
  { "country": "Philippines", "code": "63", "iso": "PH", "timezone": "Asia/Manila" },
  { "country": "Pitcairn", "code": "64", "iso": "PN", "timezone": "Pacific/Pitcairn" },
  { "country": "Poland", "code": "48", "iso": "PL", "timezone": "Europe/Warsaw" },
  { "country": "Portugal", "code": "351", "iso": "PT", "timezone": "Europe/Lisbon" }, // Note: Includes Madeira and Azores as separate time zones
  { "country": "Puerto Rico", "code": "1-787, 1-939", "iso": "PR", "timezone": "America/Puerto_Rico" },
  { "country": "Qatar", "code": "974", "iso": "QA", "timezone": "Asia/Qatar" },
  { "country": "Republic of the Congo", "code": "242", "iso": "CG", "timezone": "Africa/Brazzaville" },
  { "country": "Reunion", "code": "262", "iso": "RE", "timezone": "Indian/Reunion" },
  { "country": "Romania", "code": "40", "iso": "RO", "timezone": "Europe/Bucharest" },
  { "country": "Russia", "code": "7", "iso": "RU", "timezone": "Europe/Moscow" }, // Note: Russia spans multiple time zones
  { "country": "Rwanda", "code": "250", "iso": "RW", "timezone": "Africa/Kigali" },
  { "country": "Saint Barthelemy", "code": "590", "iso": "BL", "timezone": "America/St_Barthelemy" },
  { "country": "Saint Helena", "code": "290", "iso": "SH", "timezone": "Atlantic/St_Helena" },
  { "country": "Saint Kitts and Nevis", "code": "1-869", "iso": "KN", "timezone": "America/St_Kitts" },
  { "country": "Saint Lucia", "code": "1-758", "iso": "LC", "timezone": "America/St_Lucia" },
  { "country": "Saint Martin", "code": "590", "iso": "MF", "timezone": "America/Marigot" },
  { "country": "Saint Pierre and Miquelon", "code": "508", "iso": "PM", "timezone": "America/Miquelon" },
  { "country": "Saint Vincent and the Grenadines", "code": "1-784", "iso": "VC", "timezone": "America/St_Vincent" },
  { "country": "Samoa", "code": "685", "iso": "WS", "timezone": "Pacific/Apia" },
  { "country": "San Marino", "code": "378", "iso": "SM", "timezone": "Europe/San_Marino" },
  { "country": "Sao Tome and Principe", "code": "239", "iso": "ST", "timezone": "Africa/Sao_Tome" },
  { "country": "Saudi Arabia", "code": "966", "iso": "SA", "timezone": "Asia/Riyadh" },
  { "country": "Senegal", "code": "221", "iso": "SN", "timezone": "Africa/Dakar" },
  { "country": "Serbia", "code": "381", "iso": "RS", "timezone": "Europe/Belgrade" },
  { "country": "Seychelles", "code": "248", "iso": "SC", "timezone": "Indian/Mahe" },
  { "country": "Sierra Leone", "code": "232", "iso": "SL", "timezone": "Africa/Freetown" },
  { "country": "Singapore", "code": "65", "iso": "SG", "timezone": "Asia/Singapore" },
  { "country": "Sint Maarten", "code": "1-721", "iso": "SX", "timezone": "America/Lower_Princes" },
  { "country": "Slovakia", "code": "421", "iso": "SK", "timezone": "Europe/Bratislava" },
  { "country": "Slovenia", "code": "386", "iso": "SI", "timezone": "Europe/Ljubljana" },
  { "country": "Solomon Islands", "code": "677", "iso": "SB", "timezone": "Pacific/Guadalcanal" },
  { "country": "Somalia", "code": "252", "iso": "SO", "timezone": "Africa/Mogadishu" },
  { "country": "South Africa", "code": "27", "iso": "ZA", "timezone": "Africa/Johannesburg" },
  { "country": "South Korea", "code": "82", "iso": "KR", "timezone": "Asia/Seoul" },
  { "country": "South Sudan", "code": "211", "iso": "SS", "timezone": "Africa/Juba" },
  { "country": "Spain", "code": "34", "iso": "ES", "timezone": "Europe/Madrid" }, // Note: Includes Canary Islands as another timezone
  { "country": "Sri Lanka", "code": "94", "iso": "LK", "timezone": "Asia/Colombo" },
  { "country": "Sudan", "code": "249", "iso": "SD", "timezone": "Africa/Khartoum" },
  { "country": "Suriname", "code": "597", "iso": "SR", "timezone": "America/Paramaribo" },
  { "country": "Svalbard and Jan Mayen", "code": "47", "iso": "SJ", "timezone": "Arctic/Longyearbyen" },
  { "country": "Swaziland", "code": "268", "iso": "SZ", "timezone": "Africa/Mbabane" },
  { "country": "Sweden", "code": "46", "iso": "SE", "timezone": "Europe/Stockholm" },
  { "country": "Switzerland", "code": "41", "iso": "CH", "timezone": "Europe/Zurich" },
  { "country": "Syria", "code": "963", "iso": "SY", "timezone": "Asia/Damascus" },
  { "country": "Taiwan", "code": "886", "iso": "TW", "timezone": "Asia/Taipei" },
  { "country": "Tajikistan", "code": "992", "iso": "TJ", "timezone": "Asia/Dushanbe" },
  { "country": "Tanzania", "code": "255", "iso": "TZ", "timezone": "Africa/Dar_es_Salaam" },
  { "country": "Thailand", "code": "66", "iso": "TH", "timezone": "Asia/Bangkok" },
  { "country": "Togo", "code": "228", "iso": "TG", "timezone": "Africa/Lome" },
  { "country": "Tokelau", "code": "690", "iso": "TK", "timezone": "Pacific/Fakaofo" },
  { "country": "Tonga", "code": "676", "iso": "TO", "timezone": "Pacific/Tongatapu" },
  { "country": "Trinidad and Tobago", "code": "1-868", "iso": "TT", "timezone": "America/Port_of_Spain" },
  { "country": "Tunisia", "code": "216", "iso": "TN", "timezone": "Africa/Tunis" },
  { "country": "Turkey", "code": "90", "iso": "TR", "timezone": "Europe/Istanbul" },
  { "country": "Turkmenistan", "code": "993", "iso": "TM", "timezone": "Asia/Ashgabat" },
  { "country": "Turks and Caicos Islands", "code": "1-649", "iso": "TC", "timezone": "America/Grand_Turk" },
  { "country": "Tuvalu", "code": "688", "iso": "TV", "timezone": "Pacific/Funafuti" },
  { "country": "U.S. Virgin Islands", "code": "1-340", "iso": "VI", "timezone": "America/St_Thomas" },
  { "country": "Uganda", "code": "256", "iso": "UG", "timezone": "Africa/Kampala" },
  { "country": "Ukraine", "code": "380", "iso": "UA", "timezone": "Europe/Kiev" }, // Note: Ukraine spans multiple time zones
  { "country": "United Arab Emirates", "code": "971", "iso": "AE", "timezone": "Asia/Dubai" },
  { "country": "United Kingdom", "code": "44", "iso": "GB", "timezone": "Europe/London" },
  { "country": "United States", "code": "1", "iso": "US", "timezone": "America/New_York" }, // Note: US spans multiple time zones
  { "country": "Uruguay", "code": "598", "iso": "UY", "timezone": "America/Montevideo" },
  { "country": "Uzbekistan", "code": "998", "iso": "UZ", "timezone": "Asia/Tashkent" }, // Note: Uzbekistan spans multiple time zones
  { "country": "Vanuatu", "code": "678", "iso": "VU", "timezone": "Pacific/Efate" },
  { "country": "Vatican", "code": "379", "iso": "VA", "timezone": "Europe/Vatican" },
  { "country": "Venezuela", "code": "58", "iso": "VE", "timezone": "America/Caracas" },
  { "country": "Vietnam", "code": "84", "iso": "VN", "timezone": "Asia/Ho_Chi_Minh" },
  { "country": "Wallis and Futuna", "code": "681", "iso": "WF", "timezone": "Pacific/Wallis" },
  { "country": "Western Sahara", "code": "212", "iso": "EH", "timezone": "Africa/El_Aaiun" },
  { "country": "Yemen", "code": "967", "iso": "YE", "timezone": "Asia/Aden" },
  { "country": "Zambia", "code": "260", "iso": "ZM", "timezone": "Africa/Lusaka" },
  { "country": "Zimbabwe", "code": "263", "iso": "ZW", "timezone": "Africa/Harare" }
  
  // Dodajte više država i njihove vremenske zone
];

function App() {
  const [selectedCode, setSelectedCode] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedCountryName, setSelectedCountryName] = useState('');
  const [filteredCountryData, setFilteredCountryData] = useState(countryData);
  const [localTime, setLocalTime] = useState('');
  const [timezone, setTimezone] = useState('');
  const [capitalCity, setCapitalCity] = useState('');
  const [weather, setWeather] = useState(null);

  useEffect(() => {
    const path = window.location.pathname.slice(1).replace(/-/g, ' ');
    const selectedCountry = countryData.find(country => country.country.toLowerCase() === path.toLowerCase());
    if (selectedCountry) {
      setSelectedCode(selectedCountry.code);
      setInputValue(`+${selectedCountry.code}`);
      setSelectedCountryName(selectedCountry.country);
      setFilteredCountryData([selectedCountry]);
      setTimezone(selectedCountry.timezone);
      setCapitalCity(extractCityFromTimezone(selectedCountry.timezone)); // Extract city name from the timezone string
      fetchWeather(extractCityFromTimezone(selectedCountry.timezone)); // Fetch weather for the capital city
    } else {
      setFilteredCountryData(countryData);
    }
  }, []);

  useEffect(() => {
    if (!timezone) return;

    const updateLocalTime = () => {
      setLocalTime(moment().tz(timezone).format('HH:mm:ss'));
    };

    updateLocalTime();
    const timer = setInterval(updateLocalTime, 1000);
    return () => clearInterval(timer);
  }, [timezone]);

  const extractCityFromTimezone = (timezone) => {
    const parts = timezone.split('/');
    return parts.length > 1 ? parts[1].replace('_', ' ') : "Unknown City";
  };

  const fetchWeather = async (city) => {
    try {
      const apiKey = 'e0d30d94eade327e59430831bb7068c4'; // Your API key
      const url = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric`;
      const response = await axios.get(url);
      setWeather(response.data);
    } catch (error) {
      console.error('Error fetching weather data:', error);
      setWeather(null);
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
  
    if (name === "countrySelect") {
      const selectedCountry = countryData.find(country => country.code === value);
      if (selectedCountry) {
        setSelectedCode(selectedCountry.code);
        setInputValue(`+${selectedCountry.code}`);
        setSelectedCountryName(selectedCountry.country);
        setFilteredCountryData([selectedCountry]); // Postavlja filtrirane podatke samo za odabranu državu
        setTimezone(selectedCountry.timezone);
        setCapitalCity(extractCityFromTimezone(selectedCountry.timezone));
        fetchWeather(extractCityFromTimezone(selectedCountry.timezone));
        window.history.pushState({}, '', `/${selectedCountry.country.replace(/ /g, "-").toLowerCase()}`);
      } else {
        setSelectedCode('');
        setSelectedCountryName('');
        setInputValue('');
        setFilteredCountryData(countryData); // Vraća originalni dataset ako nema izabrane države
        window.history.pushState({}, '', '/');
      }
    } else if (name === "dialCodeInput") {
      // Ovaj dio omogućava korisnicima da mijenjaju input direktno, što može promijeniti izabrani kod
      const formattedInput = value.startsWith('+') ? value : `+${value}`;
      setInputValue(formattedInput);
  
      const selectedCountry = countryData.find(country => `+${country.code}` === formattedInput);
      if (selectedCountry) {
        setSelectedCode(selectedCountry.code);
        setSelectedCountryName(selectedCountry.country);
        setFilteredCountryData([selectedCountry]);
        setTimezone(selectedCountry.timezone);
        setCapitalCity(extractCityFromTimezone(selectedCountry.timezone));
        fetchWeather(extractCityFromTimezone(selectedCountry.timezone));
      } else {
        setSelectedCode('');
        setSelectedCountryName('');
        setFilteredCountryData(countryData);
      }
    }
  };
  
  

   return (
    <>
      <div className='header'>
        <div className='container'>
          <h2><a href='/'> Dial Codes</a></h2>
        </div>
      </div>

      {selectedCountryName && (
        <div className='country'>
          <div className='container'>
       
            {selectedCountryName && <p>{selectedCountryName} Country Code  +{selectedCode}</p>}
            
            
          </div>
        </div>
      )}

      <div className='a'>
        <div className="container">
          <div className='row'>
            <div class="box-search-wrapper">
              {/* 1*/}
              {selectedCountryName && (
                <div className='country-name'>
                  {selectedCountryName && <p>Selected Country: {selectedCountryName} +{selectedCode} <br/>
                  <p>Local Time in {selectedCountryName}: {localTime}</p>
                  <p>Capital City: {capitalCity}</p>
                 
                  
                  </p>}
                  {weather && (
              <div className='weather-info'>
                <p>Temperature: {weather.main.temp} °C</p>
                <p>Weather Description: {weather.weather[0].description}</p>
              </div>
            )}
                </div>
              )}

              <div className='box-search'>
             
                <select name="countrySelect" value={selectedCode} onChange={handleChange}>
                  <option value="">Select a country...</option>
                  {countryData.map(country => (
                    <option key={country.iso} value={country.code}>{country.country}</option>
                  ))}
                </select>
                <input
                  type="text"
                  name="dialCodeInput"
                  value={inputValue}
                  onChange={handleChange}
                  placeholder={inputValue || "Enter dial code..."}
                />
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className='container'>
        
        
        <CountryTable countryData={filteredCountryData} onRowClick={(code) => handleChange({ target: { name: "dialCodeInput", value: code.substring(1) } })} />
        
      </div>

      <div className='container'>
        <h3>About {selectedCountryName}</h3>
        <div className='about'>
        
          <p>CountryDials.com offers a comprehensive resource for making international calls from any location to any destination.</p>
          <p>This section provides information on calling {selectedCountryName}. The country code for {selectedCountryName}, +{selectedCode}, enables calls to {selectedCountryName} from abroad.</p>
          <p> This dialing code, +{selectedCode}, is used following the International Direct Dialing (IDD) prefix. It precedes the specific area codes used within {selectedCountryName}.</p>
        </div>
      </div>

      {/* Ostatak vašeg koda ... */}
     
      <footer className='footer'>
        <div className='container'>
        <p>© 2024 countrydials.com. All rights reserved.</p>
        </div>
        </footer>
      
    </>
  );

}

export default App;